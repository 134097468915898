import axios from 'axios'
import api from './api'
import Vue from 'vue';
let Base64 = require('js-base64').Base64;
import { Toast } from 'vant';
import qs from "qs";

Vue.use(Toast);
const instance = axios.create({
    baseURL: 'https://admin.gigvingroup-vn.com/api',
    timeout: 5000,
})

instance.interceptors.request.use(config => {
    if (config.method === "post") {
        config.headers = {
            'content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
        }
    }
    if (localStorage.getItem('token')) {
        config.headers = {
            'token': Base64.encode(localStorage.getItem('token')),
        }
    }
    return config
}, err => {
    console.error('Yêu cầu không thành công', err)
})


instance.interceptors.response.use(res => {
    if (res.data.msg === "Lỗi xác thực") {
        localStorage.clear();
        this.$router.push({ path: '/Login' })
    }
    return res
}, err => {

    return Promise.reject(err);
})

async function http(option = {}) {
    let result = null
    if (option.method === 'get' || option.method === 'delete') {
        await instance[option.method](
            api[option.url],
            { params: option.data }
        ).then(res => {
            result = res.data
            result.data = JSON.parse(Base64.decode(result.data));
        }).catch(err => {
            result = err
        })
    } else if (option.method === 'post' || option.method === 'put') {

        await instance[option.method](
            api[option.url],
            qs.stringify(option.data)
        ).then(res => {
            result = res.data;
            result.data = JSON.parse(Base64.decode(result.data));
        }).catch(err => {
            result = err
        })
    }
    return result
}

export default http
