<template>
  <div class="container page">
    <van-nav-bar :title="this.lottery.name" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="history_popup"></div>
    <div class="wrapper">
      <div class="options-bar">
        <div class="game">
          <div class="record">
            <div class="period">
              <div class="left">
                <div class="flex just-center item-center">
                  <van-image round class="cover" :src="this.lottery.ico">
                    <template v-slot:loading>
                      <van-loading type="spinner" />
                    </template>
                  </van-image>
                  <div class="name-lottery">{{ this.lottery.name }}</div>
                </div>
                <div>
                  <div class="number-lottery">
                    {{ this.lottery.next_expect }}
                  </div>
                </div>
              </div>

              <div class="right">
                <div class="text">Thời gian còn lại để đăng ký</div>

                <van-count-down
                  :time="time"
                  format="HH:mm:ss"
                  @finish="check()"
                >
                  <template #default="timeData">
                    <span class="block">{{ timeData.hours }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.minutes }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.seconds }}</span>
                  </template>
                </van-count-down>

                <!-- <van-count-down :time="time" @finish="check()"></van-count-down> -->
              </div>
            </div>
            <div
              class="linear-gradient"
              style="
                background: linear-gradient(
                  to right,
                  rgba(126, 86, 120, 0),
                  rgb(230, 195, 161),
                  rgba(126, 86, 120, 0)
                );
              "
            ></div>
          </div>
          <div class="sumValueTwoSides">
            <div
              class="rectangle large"
              :class="{ active: choose[v.type] }"
              v-for="(v, key) in lottery_peilv_list"
              :key="key"
              @click="choosePlay(v.type, v.name)"
            >
              <div class="wrapper">
                <div class="content">
                  <p class="name-text large">{{ v.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="bar">
          <div class="left">
            <div
              class="item"
              @click="shopping ? (shopping = false) : (shopping = true)"
            >
              <van-icon name="cart-o" class="jixuanico" />
              <span class="text">Hoàn thiện</span>
            </div>
            <!-- <div class="line"></div> -->
          </div>
          <div class="mid">
            <span class="text">Số dư: </span>
            <span class="text num">{{
              Number(this.userInfo.money).toLocaleString("us-UK")
            }}</span>
            <span class="text"> </span>
          </div>
          <div class="right" @click="jiesuan()">Xác nhận</div>
        </div>
        <div class="wrapper" :class="{ active: shopping }">
          <div class="item">
            <span class="label">Đang chọn：</span>
            <div class="bet-number">{{ this.shopchoose }}</div>
            <van-icon
              name="arrow-down"
              :class="{ up: !shopping, down: shopping }"
              @click="shopping ? (shopping = false) : (shopping = true)"
            />
          </div>
          <div class="item">
            <span class="label">Số tiền：</span>
            <div class="amount-wrapper">
              <van-field
                v-model="money"
                type="digit"
                placeholder="Số tiền cho mỗi lựa chọn"
              />
              <span class="label"> </span>
            </div>
          </div>
          <div class="item">
            <div class="part">
              <span>Tổng cộng: </span>
              <span class="number">{{ this.formData.length }}</span>
            </div>
            <div class="part">
              <span>Tổng tiền: </span>
              <span class="number">{{
                this.formData.length * this.money
              }}</span>
              <span> </span>
            </div>
          </div>
        </div>
      </div>
      <van-popup v-model="jiesuanpage" get-container="body">
        <div class="confirm-order-modal">
          <div class="head van-hairline--bottom">
            <p class="text">Mức lương</p>
          </div>
          <ui class="list">
            <li
              v-for="(v, key) in formData"
              :key="key"
              class="lise-item van-hairline--bottom"
            >
              <div class="main">
                <p class="bet-name">{{ v.name }}</p>
                <p class="detail-text">1 x {{ money }} = {{ money }}</p>
              </div>
              <van-icon @click="clearChooes(v.type)" name="close" />
            </li>
          </ui>
          <div class="sub-bar">
            <van-button
              class="item cancel-btn"
              type="default"
              @click="allClear()"
              >Xóa</van-button
            >
            <van-button class="item sub-btn" type="default" @click="doSub"
              >Xác nhận</van-button
            >
          </div>
        </div>
      </van-popup>
      <van-popup v-model="active" position="top" :style="{ height: '70%' }">
        <div>
          <div class="wrapper">
            <div class="item header">
              <div class="left font-weight">Lịch sử</div>
              <div class="right font-weight">Kết quả</div>
            </div>
            <div class="item" v-for="(v, key) in lottery_list" :key="key">
              <div class="left font-weight">{{ v.expect }}</div>
              <div class="right font-weight">
                <div class="kuaisan-ball left">
                  <span
                    class="res-des middle"
                    >{{(v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? "A" : "B"}}</span
                  >
                  <span class="res-des middle">{{
                    (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0
                      ? "C"
                      : "D"
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
var time;
var count = 0;
export default {
  data() {
    return {
      jiesuanpage: false,
      choose: {
        A: false,
        B: false,
        C: false,
        D: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false,
        13: false,
        14: false,
        15: false,
        16: false,
        17: false,
        18: false,
      },
      playgame: false,
      shopping: false,
      isLoading: false,
      play: {},
      lottery_peilv_list: {},
      lottery_list: {},
      active: false,
      userInfo: {},
      lottery: {},
      shanzi_1: "0",
      shanzi_2: "0",
      shanzi_3: "0",
      sum: 0,
      size: "",
      double: "",
      time: 0,
      shopchoose: "Chưa chọn",
      gameitem: "",
      formData: [],
      money: "",
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    doSub() {
      if (this.money === "0") {
        this.$toast("Số tiền sai!");
        return false;
      }
      if (this.formData.length === 0) {
        this.$toast("Bạn chưa chọn!");
        return false;
      } else if (this.money === "") {
        this.$toast("Vui lòng điền số tiền!");
        return false;
      } else {
        if (this.userInfo.money - this.money * this.formData.length < 0) {
          this.$toast("Số dư không đủ, vui lòng liên hệ CSKH!");
          return false;
        } else {
          this.$http({
            method: "post",
            data: {
              item: this.gameitem,
              money: this.money,
              lid: this.lottery.id,
              mid: this.userInfo.id,
              expect: this.lottery.now_expect,
            },
            url: "game_place_order",
          }).then((res) => {
            if (res.code === 200) {
              this.$toast(res.msg);
              this.allClear();
              this.getUserInfo();
            } else if (res.code === 401) {
              this.$toast(res.msg);
            }
          });
          return true;
        }
      }
    },
    allClear() {
      for (var i = 0; i < this.formData.length; i++) {
        this.choose[this.formData[i]["type"]] = false;
      }
      this.formData.length = 0;
      this.money = "";
      this.shopchoose = "Chưa chọn";
      this.gameitem = "";
      this.shopping = false;
      this.jiesuanpage = false;
    },
    jiesuan() {
      if (this.formData.length === 0) {
        this.$toast("Bạn chưa chọn!");
        return false;
      } else if (this.money === "") {
        this.$toast("Chưa nhập số tiền!");
        return false;
      } else {
        this.jiesuanpage
          ? (this.jiesuanpage = false)
          : (this.jiesuanpage = true);
      }
    },
    clearChooes(type) {
      for (var i = 0; i < this.formData.length; i++) {
        if (type === this.formData[i]["type"]) {
          this.formData.splice(i, 1);
          this.choose[type] = false;
        }
      }
      if (this.formData.length >= 1) {
        for (var j = 0; j < this.formData.length; j++) {
          if (j === 0) {
            this.shopchoose = this.formData[j]["name"];
            this.gameitem = this.formData[j]["type"];
          } else {
            this.shopchoose += "," + this.formData[j]["name"];
            this.gameitem += "," + this.formData[j]["type"];
          }
        }
      } else {
        this.shopchoose = "Chưa chọn";
        this.gameitem = "";
        this.shopping = false;
      }
      if (this.formData.length === 0) {
        this.jiesuanpage = false;
      }
    },
    choosePlay(type, name) {
      if (this.choose[type] === true) {
        this.choose[type] = false;
        for (var i = 0; i < this.formData.length; i++) {
          if (type === this.formData[i]["type"]) {
            this.formData.splice(i, 1);
          }
        }
      } else {
        this.formData.push({ name: name, type: type });
        this.choose[type] = true;
      }

      if (this.formData.length === 1) {
        this.shopping = true;
      }
      if (this.formData.length >= 1) {
        for (var j = 0; j < this.formData.length; j++) {
          if (j === 0) {
            this.shopchoose = this.formData[j]["name"];
            this.gameitem = this.formData[j]["type"];
          } else {
            this.shopchoose += "," + this.formData[j]["name"];
            this.gameitem += "," + this.formData[j]["type"];
          }
        }
      } else {
        this.shopchoose = "Chưa chọn";
        this.gameitem = "";
        this.shopping = false;
      }
    },
    check() {
      if (!localStorage.getItem("token")) {
        this.$router.push({ path: "/Login" });
      } else {
        time = window.setInterval(() => {
          setTimeout(() => {
            this.getUserInfo();
            this.getLotteryInfo();
            this.getLotteryList();
            count++;
            if (count > 5) {
              clearInterval(time);
              count = 0;
            }
          }, 0);
        }, 300);
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast("Thành công!");
        this.getLotteryList();
        this.isLoading = false;
      }, 200);
    },
    getUserInfo() {
      this.$http({
        method: "get",
        url: "user_info",
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    getLotteryPeilv() {
      this.$http({
        method: "get",
        data: { id: this.$route.query.id },
        url: "lottery_get_peilv",
      }).then((res) => {
        if (res.code === 200) {
          this.lottery_peilv_list = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    getLotteryList() {
      this.$http({
        method: "get",
        data: { key: this.$route.query.key },
        url: "lottery_get_one_list",
      }).then((res) => {
        if (res.code === 200) {
          this.lottery_list = res.data;
          this.getLotteryPeilv();
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    getLotteryInfo() {
      this.$http({
        method: "get",
        data: { key: this.$route.query.key },
        url: "lottery_get_info",
      }).then((res) => {
        if (res.code === 200) {
          if (
            parseFloat(this.userInfo.money) < parseFloat(res.data.condition)
          ) {
            this.$toast("Liên hệ với CSKH để nhận nhiệm vụ này!");
            this.$router.push({ path: "/Home" });
            return false;
          }
          this.lottery = res.data;
          this.time = res.data.second * 1000;

          console.log("TIMEEE", this.time);

          if (this.time / 1000 === 59) {
            this.$toast("Rút thành công：" + this.lottery.now_expect);
          }
          // this.shanzi_1 =
          //   "img/lottery/shaizi/" + res.data?.opencode[0] + ".png";
          // this.shanzi_2 =
          //   "img/lottery/shaizi/" + res.data?.opencode[1] + ".png";
          // this.shanzi_3 =
          //   "img/lottery/shaizi/" + res.data?.opencode[2] + ".png";
          // this.sum =
          //   res.data.opencode[0] + res.data.opencode[1] + res.data.opencode[2];
          if (this.sum >= 11 && this.sum <= 18) {
            this.size = "A";
          } else if (this.sum >= 3 && this.sum <= 10) {
            this.size = "B";
          }
          if (this.sum % 2 === 0) {
            this.double = "C";
          } else {
            this.double = "D";
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.getUserInfo();
      this.getLotteryInfo();
      this.getLotteryList();
    }
  },
  destroyed() {
    clearInterval(time);
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.game-button {
  display: flex;
  justify-content: space-around;
}

.colon {
  display: inline-block;
  margin: 0 4px;
  color: #e6c3a1;
}

.block {
  vertical-align: middle;
  color: #fff;
  font-size: 40px;
  text-align: center;
  background-color: #e6c3a1;
  padding: 10px;
  font-weight: 700;
}

.game-button div {
  display: block;
  text-align: center;
  width: 100%;
  background-color: #e6c3a1;
  border: 0.02667rem solid #e6c3a1;
  border-radius: 15px;
  color: #fff;
  overflow: hidden;
  padding: 0.6rem 0;
  margin: 0 1rem;
}

.game-button div.active {
  background-color: #7e5678;
  border: 0.02667rem solid #7e5678;
  color: #fff;
}

.game-detail .ketqua {
  margin: 1rem;
  background: #fff;
  border-radius: 25px;
}

.nav-bar .right {
  padding-left: 8px;
  padding-right: 8px;
  color: #fff;
  font-size: 28px;
  border-radius: 10px;
  border: 2px solid #fff;
  line-height: 60px;
}
.record {
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 #cacaca;
  background: #7e5678;
  border-radius: 15px;
  margin: 1rem;
}
.record .period {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
.record .period .cover {
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
}

.van-count-down {
  color: #ff253f;
  font-size: 45px;
  margin-top: 10px;
  float: right;
}
.linear-gradient {
  width: 100%;
  height: 2px;
}
.record .recent {
  display: flex;
  align-items: center;
  height: 110px;
}
.kuaisan-ball .left {
  justify-content: flex-start;
}
.kuaisan-ball {
  flex: 1;
  display: flex;
  align-items: center;
}

.kuaisan-ball .res-img {
  width: 70px;
  height: 70px;
  margin-right: 30px;
}
.kuaisan-ball .res-des {
  font-weight: 700;
  text-align: center;
  color: #2c3e50;
}
.kuaisan-ball .res-des.middle {
  width: 15%;
  font-size: 35px;
}
.van-icon {
  font-size: 40px;
}
.down {
  transition: all 0.5s;
}
.up {
  transform: rotate(180deg);
  transition: all 0.5s;
}
.wrapper {
  position: relative;
  flex: 1;
  overflow: hidden;
}
.options-bar {
  display: flex;
  align-items: center;
  height: calc(100% - 80px);
}
.options-bar .game {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.options-bar .game .tips {
  display: flex;
  align-items: center;
  height: 100px;
  padding: 0 20px;
}
.options-bar .game .tips .odds {
  flex: 1;
  font-size: 35px;
  font-weight: 500;
  color: #ff253f;
}
.options-bar .game .tips .play-tip {
  display: flex;
  align-items: center;
  height: 100%;
}
::v-deep .van-icon-more-o {
  color: #ff253f;
  font-size: 50px;
}
.options-bar .game .tips .play-tip .span-text {
  margin-left: 10px;
  font-size: 35px;
  font-weight: bolder;
  color: #ff253f;
}
.linear-gradient {
  width: 100%;
  height: 2px;
}
.sumValueTwoSides {
  background: white;
  margin: 1rem;
  border-radius: 15px;
  display: flex;
  padding: 15px 15px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.rectangle {
  overflow: hidden;
}
.rectangle.large {
  width: 50%;
}
.rectangle .wrapper {
  position: relative;
  padding: 50px 10px;
  background: #fff;
}
.rectangle .wrapper .content {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #ebebeb;
}
.rectangle.large .wrapper {
  margin: 0.3rem;
  border-radius: 15px;
}

.rectangle.large.active .wrapper .content {
  background: #ff253f;
}

.rectangle .wrapper .content .name-text.large {
  font-size: 40px;
}
.rectangle .wrapper .content .name-text {
  color: #2c3e50;
  font-weight: bolder;
}
.rectangle .wrapper .content .odd-text.large {
  font-size: 25px;
  margin-top: -30px;
}
.rectangle .wrapper .content .odd-text {
  text-align: center;
  color: #ff253f;
}
.bottom-bar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100px;
  z-index: 2;
}
.bottom-bar .bar .left,
.bottom-bar .bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bottom-bar .bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 #cacaca;
  z-index: 2;
}
.bottom-bar .bar .left,
.bottom-bar .bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bottom-bar .bar .left .item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  font-size: 20px;
}
.bottom-bar .bar .left .item .text {
  font-size: 22px;
  color: #7d7c7c;
}
.jixuanico {
  font-size: 45px;
}
.bottom-bar .bar .left .line {
  width: 2px;
  height: 50px;
  background: #dadada;
}
.bottom-bar .bar .mid {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bottom-bar .bar .mid .text {
  font-size: 30px;
  font-weight: 500;
  color: #2c3e50;
}
.bottom-bar .bar .mid .text.num {
  margin: 0 5px;
  color: #ff253f;
}
.bottom-bar .bar .right {
  padding: 0 30px;
  margin: 0 30px;
  color: #fff;
  background: linear-gradient(270deg, #e6c3a1, #7e5678);
  font-size: 40px;
  font-weight: 500;
  height: 70px;
  line-height: 70px;
  border-radius: 50px;
}
.rectangle.active .wrapper {
  background-color: #ff253f !important;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}
::v-deep .van-popup {
  position: absolute;
}
::v-deep .van-overlay {
  position: absolute;
  background-color: rgb(70 67 67 / 70%);
}
::v-deep .van-popup--top {
  top: -1px;
}

.wrapper .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #ebebeb;
}
.wrapper .item.header {
  background: #b08b8c !important;
  color: #fff !important;
  padding: 1rem;
  font-weight: 600;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.wrapper .item.header .left {
  color: #fff !important;
}

.wrapper .item.header .right {
  color: #fff !important;
}

.wrapper .item .left {
  width: 40%;
  font-size: 30px;
  text-align: center;
  font-weight: 500;
  color: #2c3e50;
}
.font-weight {
  font-weight: 700 !important;
}
.flex {
  display: flex;
}
.just-center {
  justify-content: center;
}
.item-center {
  align-items: center;
}
.wrapper .item .right {
  flex: 1;
  display: flex;
  font-size: 30px;
  justify-content: center;
  overflow: hidden;
  color: #2c3e50;
}
.wrapper .item .kuaisan-ball .left {
  justify-content: flex-start;
}
.wrapper .item .kuaisan-ball {
  margin-left: 20px;
  flex: 1;
  display: flex;
  align-items: center;
}
.wrapper .item .kuaisan-ball .res-img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}
.wrapper .item .kuaisan-ball .res-des {
  text-align: center;
  color: #2c3e50;
}
.wrapper .item .kuaisan-ball .res-des.middle {
  width: 100%;
  font-size: 25px;
  border: 1px solid #ebebeb;
  margin: 0 10px;
  padding: 3px 0;
  border-radius: 10px;
  background: #ebebeb;
}
.play-type-tip {
  position: unset;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 650px;
  height: 700px;
  max-height: 50%;
  z-index: 10;
  border-radius: 30px;
  overflow: hidden;
  background-color: #fff;
  color: #2c3e50;
}
.play-type-tip .title {
  line-height: 90px;
  background: linear-gradient(90deg, #7e5678, #e6c3a1);
  text-align: center;
  color: #fff;
  font-size: 35px;
  font-weight: 500;
}
.mask {
  background-color: rgb(0 0 0 / 0%);
  animation-duration: 0.35s;
}
.play-type-tip .wrapper {
  height: calc(100% - 10px);
  background-color: transparent;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.play-type-tip .wrapper .item {
  padding: 40px 50px;
  display: flex;
  align-items: flex-start;
}
.play-type-tip .wrapper .item .van-icon {
  color: #e6c3a1;
  font-size: 60px;
}
.play-type-tip .wrapper .item .content .content-title {
  margin-top: 22px;
  font-size: 35px;
  font-weight: 500;
  color: #2c3e50;
  line-height: 0px;
}
.play-type-tip .wrapper .item .content .content-detail {
  margin-top: 5px;
  font-size: 22px;
  color: #2c3e50;
  line-height: 30px;
}
.play-type-tip .wrapper .item .content {
  flex: 1;
  margin-left: 30px;
}
.rectangle.active .wrapper {
  background-color: #ff253f !important;
}
.rectangle.active .wrapper .name-text,
.rectangle.active .wrapper .odd-text {
  color: #fff !important;
}
.bottom-bar .wrapper {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  padding: 20px 20px 10px 20px;
  height: 230px;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 0 10px 0 #cacaca;
  transition: transform 0.3s cubic-bezier(0.21, 1.02, 0.55, 1.01);
}
.bottom-bar .wrapper.active {
  transform: translateY(-100%);
}
.bottom-bar .wrapper .item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 65px;
}
.bottom-bar .wrapper .item .label {
  font-size: 30px;
  line-height: 30px;
  color: #2c3e50;
}
.bottom-bar .wrapper .item .bet-number {
  flex: 1;
  margin: 0 16px;
  overflow: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  color: #ff253f;
  font-size: 30px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
}
.bottom-bar .wrapper .item .amount-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.van-cell {
  font-size: 30px;
  line-height: 50px;
}
.bottom-bar .wrapper .item .part {
  margin-right: 20px;
}
.bottom-bar .wrapper .item .part span {
  font-size: 30px;
  vertical-align: center;
  color: #2c3e50;
}
.bottom-bar .wrapper .item .part .number {
  margin: 0 5px;
  color: #ff253f;
  font-weight: 500;
}
::v-deep .van-field__control {
  color: #ff253f;
}
.confirm-order-modal {
  position: unset;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 20px 30px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 610px;
  height: 680px;
  max-height: 50%;
  z-index: 10;
  background-color: #fff;
  border-radius: 30px;
}
.confirm-order-modal .head {
  position: relative;
  height: 80px;
}
.confirm-order-modal .head .text {
  padding: 0 20px;
  height: 30px;
  line-height: 10px;
  text-align: center;
  font-size: 35px;
  font-weight: 500;
  color: #7e5678;
}
::v-deep .confirm-order-modal .van-hairline--bottom::after {
  border-bottom-width: 2px;
}
.van-popup--center {
  border-radius: 30px;
}
.confirm-order-modal .list {
  flex: 1;
  padding: 0 10px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.confirm-order-modal .list .lise-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
}
.confirm-order-modal .list .lise-item .main {
  flex: 1;
  overflow: hidden;
}
.confirm-order-modal .list .lise-item .main .bet-name {
  color: #ff253f;
  font-size: 35px;
  font-weight: 500;
  line-height: 0px;
  word-wrap: break-word;
  word-break: break-all;
}
.confirm-order-modal .list .lise-item .main .detail-text {
  line-height: 0px;
  font-size: 25px;
  color: #979799;
}
.confirm-order-modal .list .lise-item {
  color: #ff253f;
}
.confirm-order-modal .sub-bar {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: space-around;
}
.confirm-order-modal .sub-bar .item {
  min-width: 40%;
  height: 80px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 35px;
  font-weight: 500;
}
.confirm-order-modal .sub-bar .item.cancel-btn {
  border: 2px solid #979799;
  color: #979799;
  background-color: #fff;
}
.confirm-order-modal .sub-bar .item.sub-btn {
  background: linear-gradient(270deg, #e6c3a1, #7e5678);
  color: #fff;
  border: 0;
}
.number-lottery {
  color: #fff;
  margin-top: 15px;
  font-size: 35px;
  font-weight: 600;
}
.period .left {
  width: 50%;
}

.period .right {
  height: 100%;
  border-left: 0.02667rem dashed #fff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.period .right .text {
  font-size: 25px;
  color: #fff;
  margin-bottom: 20px;
}
.period .left div {
  justify-content: flex-start;
}

.period .left div.cover {
  margin-right: 1rem;
}
.name-lottery {
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 10px;
  color: #fff;
  font-size: 18px;
}
.van-count-down {
  text-align: right;
}
</style>
