<template>
  <div class="container page">
    <van-nav-bar title="Điền thông tin CCCD/CMND" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="main-box">
      <div class="label">Nhập chính xác thông tin</div>
      <van-cell-group>
        <van-field
          v-model="cccd"
          label="Số CCCD"
          type="digit"
          placeholder="Nhập số cccd, cmnd"
        />
        <van-field
          v-model="ngaycap"
          label="Ngày cấp"
          type="text"
          placeholder="14/05/2005"
        />
      </van-cell-group>
    </div>
    <van-button class="bindCard" type="default" @click="bindCard()"
      >Xác nhận</van-button
    >
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      userInfo: {},
      cccd: "",
      ngaycap: "",
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    bindCard() {
      if (this.cccd === "" || this.cccd === null || this.cccd === undefined) {
        this.$toast.fail("Vui lòng nhập số CCCD!");
        return false;
      }
      if (
        this.ngaycap === "" ||
        this.ngaycap === null ||
        this.ngaycap === undefined
      ) {
        this.$toast.fail("Vui lòng nhập ngày cấp!");
        return false;
      }
        this.$http({
          method: "post",
          data: { cmnd: this.cccd, ngaycap: this.ngaycap },
          url: "user_set_cmnd",
        }).then((res) => {
          if (res.code === 200) {
            this.$router.push({ path: "/Mine" });
            this.$toast(res.msg);
          } else if (res.code === 401) {
            this.$toast(res.msg);
          }
        });
    },
    getUserInfo() {
      this.$http({
        method: "get",
        url: "user_info",
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.cccd = res.data.cmnd;
          this.ngaycap = res.data.ngaycap;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.getUserInfo();
    }
  },
};
</script>
  
  <style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 32px;
  line-height: 80px;
}
.van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.bankbox {
  padding: 15px;
  color: #2c3e50;
  background-color: #fff;
}
.bankbox .title {
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}
.main-box {
  background: #fff;
}
.main-box .label {
  padding: 20px;
  font-size: 35px;
  color: #797878;
}
::v-deep .van-picker__toolbar {
  height: 50px;
}
::v-deep .van-picker__cancel,
.van-picker__confirm {
  padding: 0 20px;
  font-size: 20px;
}
::v-deep .van-picker-column {
  font-size: 40px;
}
.main-box p {
  padding: 0 20px;
  font-size: 30px;
  color: #ee0a24;
}
.bindCard {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(90deg, #e6c3a1, #7e5678);
}
</style>
  