<template>
  <div class="home-container">
    <!-- <div class="linear-bg"></div> -->
    <div class="home-scroll">
      <div class="header flex justbet" style="align-items: center">
        <div class="flex justcenter" style="align-items: center">
          <img
            class="logo-img"
            height="40px"
            :src="
              this.$store.getters.getBaseInfo.ico !== undefined
                ? this.$store.getters.getBaseInfo.ico
                : '/img/null.png'
            "
          />
        </div>
        <div>
          <input
            placeholder="Tìm kiếm"
            style="
              border-radius: 16px;
              border: 2px solid #ff5722;
              padding: 5px;
              margin-right: 5px;
            "
          />
        </div>
      </div>

      <div
        class="scrollngang"
        style="
          display: flex;
          overflow: auto;
          background: linear-gradient(
            180deg,
            #ffe2c6,
            rgba(255, 241, 235, 0.4) 53.12%,
            #fff 88.98%,
            #fff
          );
          padding: 10px;
        "
      >
        <div
          class="item"
          v-for="(v, key) in listscrollngang"
          :key="key"
          listscrollngang
          style="
            background-color: #fff;
            padding: 5px;
            border-radius: 8px;
            margin-right: 10px;
          "
        >
          <div class="banneritem" style="display: flex; flex-direction: column">
            <div style="">
              <img
                width="100%"
                height="100px"
                :src="v.img"
                alt=""
                style="border-radius: 8px"
              />
            </div>
            <div>
              {{ v.name }}
            </div>
            <div class="price">{{ v.price }}</div>
            <ul data-v-bd8da8a4="" class="sc-q9qagu-9 bIsYGD">
              <li
                data-v-bd8da8a4=""
                class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"
              >
                <span
                  data-v-bd8da8a4=""
                  class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"
                ></span
                ><span data-v-bd8da8a4="">Hôm nay</span>
              </li>
              <li
                data-v-bd8da8a4=""
                class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"
              >
                <span
                  data-v-bd8da8a4=""
                  class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"
                ></span
                ><span data-v-bd8da8a4="">{{ v.address }}</span>
              </li>
            </ul>
          </div>
          <div class="timeloc"></div>
        </div>
      </div>

      <div
        class="scrolldoc"
        style="
          display: flex;
          overflow: auto;
          flex-direction: column;
          padding: 10px;
          background-color: azure;
        "
      >
        <div
          class="item"
          v-for="(v, key) in listscrolldoc"
          :key="key"
          style="
            background-color: #azure;
            padding: 5px;
            border-radius: 8px;
            margin-bottom: 10px;
          "
        >
          <div class="banneritem" style="display: flex">
            <div style="">
              <img width="150px" height="100px" :src="v.img" alt="" />
            </div>
            <div
              style="
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              "
            >
              <div>{{ v.name }}</div>
              <div class="price">{{ v.price }}</div>
              <ul data-v-bd8da8a4="" class="sc-q9qagu-9 bIsYGD">
                <li
                  data-v-bd8da8a4=""
                  class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"
                >
                  <span
                    data-v-bd8da8a4=""
                    class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"
                  ></span
                  ><span data-v-bd8da8a4="">{{ v.time }}</span>
                </li>
                <li
                  data-v-bd8da8a4=""
                  class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"
                >
                  <span
                    data-v-bd8da8a4=""
                    class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"
                  ></span
                  ><span data-v-bd8da8a4="">{{ v.address }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notice: "Đang tải......",
      banners: [{}],
      basicData: [],
      gameitem: [{}, {}, {}, {}],
      movielist_0: [{}, {}, {}, {}],
      movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
      listscrollngang: [
        {
          img: "/img/home/1ac35176b3d54ae482c0ec2a9486a229.jpg",
          name: "Cần tuyển kỹ thuật viên massage - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ",
          price: "Thỏa thuận",
          time: "Hôm nay",
          address: "Quận Hoàng Mai, Hà Nội",
        },
        {
          img: "/img/home/5e608f5adece454ebfe371874e129589.jpg",
          name: "CẦN TUYỂN: 10 NAM LAO ĐỘNG PHỔ THÔNG TẠI HOÀNG MAI",
          price: "Thỏa thuận",
          time: "Hôm nay",
          address: "Quận Hoàng Mai, Hà Nội",
        },
        {
          img: "/img/home/8fe2a51ea1244e768d593027a2988840.jpg",
          name: "Công Ty TNHH Tân An Bình tuyển 2 nữ nhân viên kế toán bán hàng.",
          price: "Thỏa thuận",
          time: "Hôm nay",
          address: "Quận Hoàng Mai, Hà Nội",
        },
        {
          img: "/img/home/category-2.png",
          name: "Tuyển nhân viên phục vụ nhà hàng hàn quốc có phụ tiền cơm",
          price: "Thỏa thuận",
          time: "Hôm nay",
          address: "Quận Hoàng Mai, Hà Nội",
        },
        {
          img: "/img/home/cd73b959061d4e89821d3b90e889982a.jpg",
          name: "CẦN TUYỂN: 05 THỢ HÀN CÓ KINH NGHIỆM",
          price: "Thỏa thuận",
          time: "Hôm nay",
          address: "Quận Hoàng Mai, Hà Nội",
        },
      ],
      listscrolldoc: [
        {
          img: "/img/home/category-2.png",
          name: "Tuyển Dụng Nhân Viên Fulltime Nhà Hàng Tại Quận 2",
          price: "Thỏa thuận",
          time: "Hôm nay",
          address: "TP. Hồ Chí Minh",
        },
        {
          img: "https://cdn.chotot.com/drn735XTQ0tKsyOiMPgRUNoRXNq51-YOU2tecHHMcH0/preset:view/plain/2f201603495296ce8935d01d8efc64fe-2858098777798005590.jpg",
          name: "Nhân Viên Tư Vấn Và Tiếp Nhận Hồ Sơ",
          price: "Thỏa thuận",
          time: "Hôm nay",
          address: "Quận Thanh Xuân, Hà Nội",
        },
        {
          img: "https://cdn.chotot.com/xRMfA8kK1b-nT6PxolR4a6EwKq7OA2EqnQ2IsOSCOVM/preset:view/plain/aca02b5f21cc9df2d31456c913871c00-2859982371861621307.jpg",
          name: "Tuyển Nữ Nhân Viên Biết Tiếng Anh Bán Hàng Tranhhn",
          price: "Thỏa thuận",
          time: "Hôm nay",
          address: "Quận Hà Đông, Hà Nội",
        },
        {
          img: "/img/home/category-2.png",
          name: "Tuyển Phục Vụ, Tiếp Thực Quận 3",
          price: "Thỏa thuận",
          time: "Hôm nay",
          address: "TP. Hồ Chí Minh",
        },
        {
          img: "/img/home/cd73b959061d4e89821d3b90e889982a.jpg",
          name: "CẦN TUYỂN: 05 THỢ HÀN CÓ KINH NGHIỆM",
          price: "Thỏa thuận",
          time: "Hôm nay",
          address: "Quận Hoàng Mai, Hà Nội",
        },
        {
          img: "https://cdn.chotot.com/wqIPNIj4BpFTVrLFiAt_KPvkPx5V6CjWR4NF3DGVHgg/preset:listing/plain/92e3a4dcb6cac12e774a0130160cf239-2839259690466809556.jpg",
          name: "Nhân Viên Bán Hàng Tiêu Dùng",
          price: "Thỏa thuận",
          time: "Hôm nay",
          address: "Quận Hoàng Mai, Hà Nội",
        },
        {
          img: "https://cdn.chotot.com/wV0q9whESN0bjgpWK3poYq5Bz2DTs1LoZ3Irr9N9HYk/preset:listing/plain/70b83f6b01f377bbe89e0ccff0439cfc-2862948373504637054.jpg",
          name: "Tuyển 7 NV Chế Biến Thực Phẩm Emart Gò Vấp",
          price: "Thỏa thuận",
          time: "Hôm nay",
          address: "Gò Vấp, HCM",
        },
      ],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: "auto",
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      bannerSwiperOption: {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        speed: 800,
        autoplay: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
      },
    };
  },
  methods: {
    gotoMenu(router) {
      this.$router.replace(router);
    },
    toLottery(key, id) {
      if (!localStorage.getItem("token")) {
        this.$router.push({ path: "/Login" });
      } else {
        this.$router.push({ path: "/Lottery?key=" + key + "&id=" + id });
      }
    },
    toPlayVideo(id) {
      if (!localStorage.getItem("token")) {
        this.$router.push({ path: "/Login" });
      } else {
        this.$router.push({ path: "/PlayVideo?id=" + id });
      }
    },
    onRefresh() {
      setTimeout(() => {
        // this.getBasicConfig();
        this.isLoading = false;
        this.$toast("Thành công!");
      }, 500);
    },
    // getBasicConfig() {
    //   this.$http({
    //     method: "get",
    //     url: "sys_config",
    //   }).then((res) => {
    //     this.basicData = res.data;
    //     console.log(this.basicData);
    //     this.getNotice(this.basicData); //获取公告
    //     this.getBanner(this.basicData); //获取banner
    //     this.getGameItem(); //获取首页游戏列表
    //     this.getMovieList_0(this.basicData); //获取首页视频0
    //     this.getMovieList_1(this.basicData); //获取首页视频1
    //   });
    // },
    getNotice(data) {
      this.notice = data.notice;
    },
    getGameItem() {
      this.$http({
        method: "get",
        url: "lottery_hot",
      }).then((res) => {
        this.gameitem = res.data;
      });
    },
    getMovieList_0(data) {
      this.movielist_0 = data.movielist_0;
    },
    getMovieList_1(data) {
      this.movielist_1 = data.movielist_1;
    },
    getBanner(data) {
      this.banners = data.banners;
    },
  },
  mounted() {},
  created() {
    // this.getBasicConfig();
    console.log(this.$store.getters.getBaseInfo);
  },
};
</script>

<style lang='less' scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;
.linear-bg {
  height: 200px;
  background: linear-gradient(270deg, #e6c3a1, #7e5678);
}
.home-container {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
}
.linear-gradient {
  width: 100%;
  height: 2px;
  background: linear-gradient(
    to right,
    rgba(126, 86, 120, 0),
    rgb(230, 195, 161),
    rgba(126, 86, 120, 0)
  );
}
::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
  min-width: 40px;
}
.notice-swipe {
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}
::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}
.banner {
  width: 100%;
  margin-top: -23%;
}
.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}
::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}
::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}
.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.van-grid {
  justify-content: center;
}
.hot-game {
  width: 100%;
  height: 100%;
}
.hot-title-div {
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hot-title-div > div:first-child {
  // width: 130px;
  text-transform: uppercase;
}
.hot-title-div div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hot-title-div > div:nth-child(2) span {
  font-size: 20px;
  color: #979799;
}
.hot-title-div > div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #2c3e50;
}

.hot-title-div > div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 700;
  color: #2c3e50;
}
.hot-title-div > div:nth-child(2) span {
  font-size: 25px;
  color: #979799;
}
.hot-title-div > div:first-child span:before {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  background-color: #7e5678;
  border-radius: 1px;
  margin-right: 5px;
}
.hot-game .hot-items-div {
  margin-top: -3px;
}
.hot-game .hot-items-div span {
  margin-top: 10px;
  font-size: 28px;
  color: #2c3e50;
}
.hot-recommend {
  width: 100%;
  flex: 1;
  background-color: #f2f2f5;
}
.movie_swiper {
  .swiper-slide {
    width: 80%;
  }
}
.movie_list_0 {
  width: calc(100% - 50px);
  margin: 0 auto;
}
.movie_cover {
  border-radius: 10px;
  width: 550px;
  height: 330px;
}
.movie_list_0 .movie-list-item-bottom {
  position: relative;
  width: 550px;
  bottom: 43px;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, 0.4);
}
.movie_list_0 .movie-list-item-bottom > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}
.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
}
.movie_list_0 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}
.movie_list_0 span {
  font-size: 30px;
}
.movie_list_1 {
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.movie_list_1 .movie-list-item .cover_img {
  border-radius: 10px;
  width: 335px;
  height: 290px;
}
.home-scroll {
  padding-bottom: 110px;
}
.movie_list_1 .movie-list-item {
  margin-bottom: -10px;
}

.movie_list_1 .movie-list-item-bottom {
  position: relative;
  width: 335px;
  bottom: 42px;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, 0.4);
}
.movie_list_1 .movie-list-item-bottom > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}
.movie_list_1 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}
.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right: 110px;
  font-size: 22px;
}
.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more {
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #979799;
  font-size: 30px;
}
.hot-items-div .game_item_img {
  width: 100px;
  height: 100px;
}
::v-deep .hot-items-div .game_item_img .van-image__img {
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}
</style>
