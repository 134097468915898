<template>
  <div class="movie-hall page">
    <section data-v-15a798df="" id="team" class="team mowpad relative">
      <div data-v-15a798df="" class="title">
        <h1 data-v-15a798df="" class="text-red">
          {{ this.$store.getters.getBaseInfo.name }}
        </h1>
      </div>
      <div data-v-15a798df="" class="mowtainer">
        <div data-v-15a798df="" class="mowgrid" style="margin-bottom: 3rem">
          <div
            v-for="item in banners"
            :key="item.id"
            data-v-15a798df=""
            class="member"
          >
            <div data-v-15a798df="" class="image">
              <img data-v-15a798df="" :src="item.url" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banners: [],
    };
  },
  methods: {
    getBasicConfig() {
      this.$http({
        method: "get",
        url: "sys_config",
      }).then((res) => {
        this.banners = res.data.banners;
      });
    },
  },
  mounted() {},
  created() {
    this.getBasicConfig();
    console.log(this.$store.getters.getBaseInfo);
  },
};
</script>

<style lang='less' scoped>
.text-red {
  color: red;
}
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.nav-bar {
  background: linear-gradient(90deg, #7e5678, #e6c3a1);
  height: 100px;
}
.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}

.movie-hall {
  display: flex;
  flex-direction: column;
  bottom: 100px;
  background: #f2f2f5;
}
::v-deep .van-tabs__nav {
  background: linear-gradient(to right, rgb(126, 86, 120), rgb(230, 195, 161));
}
::v-deep .van-tab {
  color: #ffffff;
  font-size: 30px;
}
::v-deep .van-tabs__line {
  bottom: 15px;
  width: 55px;
  height: 7px;
  border-radius: 0px;
  background-color: #ffffff;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}
::v-deep .van-hairline--bottom::after {
  border-bottom-width: 0px;
}
.video_swiper {
  width: 100%;
  flex: 1;
  .swiper-slide {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 100%;
    justify-content: center;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
}
.movie-list-tab {
  overflow: auto;
  height: 100%;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #2c3e50;
  font-size: 35px;
}
.movie-list-tab .hot-recommend-div {
  height: 100%;
  margin: 10px auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  //overflow: auto;
}
.list-item {
  display: flex;
  width: calc(100% - 50px);
  margin: 10px auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.list-item .movie-list-item:nth-child(odd) {
  margin-right: 10px;
}
.movie-list-item .cover_img {
  border-radius: 20px;
  width: 335px;
  height: 290px;
}
.movie-list-item {
  margin-bottom: -10px;
}
.list-item .movie-list-item-bottom {
  position: relative;
  width: 335px;
  bottom: 42px;
}
.list-item .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, 0.4);
}
.list-item .movie-list-item-bottom > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-item .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.list-item .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}
.list-item .movie-time-div {
  color: #fff;
  border-radius: 0 0 20px 20px;
  height: 35px;
}
</style>
