<template>
  <div class="convention-hall page">
    <van-nav-bar class="nav-bar" title="Nước ngoài" />
    <h2 style="margin: 5px">Việc làm Nhật Bản mới nhất</h2>
    <div class="convention-item">
      <ul>
        <li
          data-v-8ff56982=""
          class="active"
          style="background: rgb(245, 130, 32); height: 32px"
        >
          <a
            data-v-8ff56982=""
            href="javascript:void(0);"
            role="tab"
            data-toggle="tab"
            >Tất cả đơn hàng mới</a
          >
        </li>
        <li data-v-8ff56982="">
          <a
            data-v-8ff56982=""
            href="javascript:void(0);"
            role="tab"
            data-toggle="tab"
            style="height: 32px"
            >Kỹ sư đi Nhật Bản</a
          >
        </li>
        <li data-v-8ff56982="">
          <a
            data-v-8ff56982=""
            href="javascript:void(0);"
            role="tab"
            data-toggle="tab"
            style="height: 32px"
            >Đơn hàng cho nam</a
          >
        </li>
        <li data-v-8ff56982="">
          <a
            data-v-8ff56982=""
            href="javascript:void(0);"
            role="tab"
            data-toggle="tab"
            style="height: 32px"
            >Đơn hàng cho nữ</a
          >
        </li>
      </ul>
      <table class="donhang-home">
        <tbody>
          <tr>
            <td>Ngành nghề</td>
            <td>Ngày thi</td>
            <td>Giới tính</td>
            <td>Lương cơ bản</td>
          </tr>
          <tr>
            <td>
              <a
                href="javascript:void(0);"
                title="[Phí tổng 105tr] Đơn hàng chế biến thực phẩm cho nữ đi Nhật Bản"
                >Finance</a
              >
            </td>
            <td>15/09/2023</td>
            <td>Nữ</td>
            <td>3564.054 AUD</td>
          </tr>
          <tr>
            <td>
              <a
                href="javascript:void(0);"
                title="Đơn hàng Tokutei thực phẩm đầu Việt làm sủi cảo tỉnh Gunma"
                >Estate</a
              >
            </td>
            <td>25/09/2023</td>
            <td>Nam/Nữ</td>
            <td>5253.000 AUD</td>
          </tr>
          <tr>
            <td>
              <a
                href="javascript:void(0);"
                title="Tuyển sinh du học nghề Úc vừa học vừa làm cơ hội kiếm 4000 UAD"
                >Công xưởng</a
              >
            </td>
            <td>28/09/2023</td>
            <td>Nam Nữ</td>
            <td>4000 AUD</td>
          </tr>
          <tr>
            <td>
              <a
                href="javascript:void(0);"
                title="Đơn hàng chế biến thực phẩm tại Nhật Bản tuyển 18 nữ"
                >Doctor</a
              >
            </td>
            <td>18/09/2023</td>
            <td>Nữ</td>
            <td>80000.000 CNY</td>
          </tr>
          <tr>
            <td>
              <a
                href="javascript:void(0);"
                title="Tuyển 30 nữ làm đóng gói công nghiệp tại Nhật Bản làm ở tỉnh Tokyo"
                >Science and technology</a
              >
            </td>
            <td>16/09/2023</td>
            <td>Nữ</td>
            <td>157.000 Yên</td>
          </tr>
          <tr>
            <td>
              <a
                href="javascript:void(0);"
                title="Đơn hàng truyền thống tuyển 100 nữ và 20 nam làm chế biến thủy sản Nhật Bản"
                >Hotel manager</a
              >
            </td>
            <td>25/09/2023</td>
            <td>Nam Nữ</td>
            <td>5231.000 AUD</td>
          </tr>
          <tr>
            <td>
              <a
                href="javascript:void(0);"
                title="Đơn hàng dập kim loại linh kiện ô tô Nissan Nhật Bản tuyển 54 nam"
                >Cơ khí</a
              >
            </td>
            <td>24/09/2023</td>
            <td>Nam</td>
            <td>160.768 Yên</td>
          </tr>
          <tr>
            <td>
              <a
                href="javascript:void(0);"
                title="Đơn hàng chế biến đồ ăn sẵn tại Nhật Bản tuyển 60 nữ làm ở siêu thị"
                >Thực phẩm</a
              >
            </td>
            <td>15/09/2023</td>
            <td>Nữ</td>
            <td>157.000 Yên</td>
          </tr>
          <tr>
            <td>
              <a
                href="javascript:void(0);"
                title="Đơn hàng chế biến thực phẩm tại Kyoto Nhật Bản tuyển 54 nữ"
                >Thực phẩm</a
              >
            </td>
            <td>24/09/2023</td>
            <td>Nữ</td>
            <td>157.000 Yên</td>
          </tr>
          <tr>
            <td>
              <a
                href="javascript:void(0);"
                title="Đơn hàng công xưởng Nhật Bản tuyển 20 nam tại tỉnh Osaka"
                >Phân Loại</a
              >
            </td>
            <td>29/09/2023</td>
            <td>Nam</td>
            <td>167.385 Yên</td>
          </tr>
        </tbody>
      </table>
      <div data-v-8ff56982="" class="widget widget-highlight-job pagetow">
        <h2 data-v-8ff56982="" class="widget-title">
          <a
            data-v-8ff56982=""
            href="javascript:void(0);"
            title="Việc làm ngoài nước mới nhất"
            >Việc làm ngoài nước mới nhất
          </a>
        </h2>
        <div data-v-8ff56982="" class="clearfix"></div>
        <div data-v-8ff56982="" class="widget-wrap scrollbar">
          <div data-v-8ff56982="" class="widget-content">
            <ul data-v-8ff56982="">
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="Tuyển sinh du học nghề Úc vừa học vừa làm cơ hội kiếm 4000 UAD"
                  ><h3 data-v-8ff56982="">
                    Tuyển sinh du học nghề Úc vừa học vừa làm cơ hội kiếm 4000
                    UAD
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>4000 AUD
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>Úc
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="41 đơn hàng Singapore mới nhất tuyển liên tục"
                  ><h3 data-v-8ff56982="">
                    41 đơn hàng Singapore mới nhất tuyển liên tục
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>40000
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>Singapore
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="Tuyển sinh du học Úc không cần chứng chỉ tiếng anh Ielts"
                  ><h3 data-v-8ff56982="">
                    Tuyển sinh du học Úc không cần chứng chỉ tiếng anh Ielts
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>25 AUD/giờ
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>Úc
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="Xuất khẩu lao động Ba Lan tuyển Nam Nữ đóng gói"
                  ><h3 data-v-8ff56982="">
                    Xuất khẩu lao động Ba Lan tuyển Nam Nữ đóng gói
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>1.100 USD
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>Ba lan
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="Xuất khẩu lao động Ba Lan tuyển nam nữ làm thực phẩm"
                  ><h3 data-v-8ff56982="">
                    Xuất khẩu lao động Ba Lan tuyển nam nữ làm thực phẩm
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>1.100 USD
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>Ba lan
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="Thông báo chương trình xuất khẩu lao động Đức được nợ phí"
                  ><h3 data-v-8ff56982="">
                    Thông báo chương trình xuất khẩu lao động Đức được nợ phí
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>80 triệu
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>CHLB Đức
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="Visa E7 Hàn Quốc tuyển thợ cơ khí thợ điện làm việc tại nhà máy đóng tàu"
                  ><h3 data-v-8ff56982="">
                    Visa E7 Hàn Quốc tuyển thợ cơ khí thợ điện làm việc tại nhà
                    máy đóng tàu
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>2,7 triệu Won
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>Hàn Quốc
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="Đơn hàng xây dựng tại Singapore tuyển 1300 lao động phổ thông"
                  ><h3 data-v-8ff56982="">
                    Đơn hàng xây dựng tại Singapore tuyển 1300 lao động phổ
                    thông
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>3000 SGD
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>Singapore
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="Thông báo tuyển lao động đi làm việc tại CHLB Đức"
                  ><h3 data-v-8ff56982="">
                    Thông báo tuyển lao động đi làm việc tại CHLB Đức
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>2400 Eur
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>Đức
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="Xuất khẩu lao động Philippines tuyển nhân viên Sale Marketing phí O đồng"
                  ><h3 data-v-8ff56982="">
                    Xuất khẩu lao động Philippines tuyển nhân viên Sale
                    Marketing phí O đồng
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>36 Triệu đồng
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>Philippines
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="Xuất khẩu lao động Australia Úc Tuyển 1500 nam nữ làm nông nghiệp"
                  ><h3 data-v-8ff56982="">
                    Xuất khẩu lao động Australia Úc Tuyển 1500 nam nữ làm nông
                    nghiệp
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>85 triệu
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>Australia Úc
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="Tuyển đầu bếp làm việc tại Đức không yêu cầu tiếng chỉ yêu cầu bảo hiểm"
                  ><h3 data-v-8ff56982="">
                    Tuyển đầu bếp làm việc tại Đức không yêu cầu tiếng chỉ yêu
                    cầu bảo hiểm
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>2.800 Euro
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>CHLB Đức
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="Xuất khẩu lao động New Zealand đơn hàng tuyển nam nữ phục vụ nhà hàng"
                  ><h3 data-v-8ff56982="">
                    Xuất khẩu lao động New Zealand đơn hàng tuyển nam nữ phục vụ
                    nhà hàng
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>80 triệu
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>New Zealand
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="Tuyển lao động làm xây dựng tại New Zealand"
                  ><h3 data-v-8ff56982="">
                    Tuyển lao động làm xây dựng tại New Zealand
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>4000- 6,500 NZD
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>New Zealand
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="Ngành nông nghiệp tuyển lao động nam nữ làm farm ở New Zealand"
                  ><h3 data-v-8ff56982="">
                    Ngành nông nghiệp tuyển lao động nam nữ làm farm ở New
                    Zealand
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>5000-5500 NZ
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>New Zealand
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="Tuyển thợ hàn đi Hungary và Slovakia Châu Âu phí rẻ lương cao"
                  ><h3 data-v-8ff56982="">
                    Tuyển thợ hàn đi Hungary và Slovakia Châu Âu phí rẻ lương
                    cao
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>2.200 Euro
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>Hungary
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="Xuất khẩu lao động Hungary với 5 đơn hàng tuyển mới"
                  ><h3 data-v-8ff56982="">
                    Xuất khẩu lao động Hungary với 5 đơn hàng tuyển mới
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>1.100 USD
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>Hungary
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="3 đơn hàng xuất khẩu lao động Hungary làm Phiên dịch, đốc công, điện tử"
                  ><h3 data-v-8ff56982="">
                    3 đơn hàng xuất khẩu lao động Hungary làm Phiên dịch, đốc
                    công, điện tử
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>2000USD
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>Hungary
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="Xuất khẩu lao động Đài Loan tuyển 15 nam nữ làm sản xuất máy chạy bộ tại Đài Trung"
                  ><h3 data-v-8ff56982="">
                    Xuất khẩu lao động Đài Loan tuyển 15 nam nữ làm sản xuất máy
                    chạy bộ tại Đài Trung
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>23.800 Đài tệ
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>Đài Loan
                  </h4></a
                >
              </li>
              <li data-v-8ff56982="">
                <a
                  data-v-8ff56982=""
                  href="javascript:void(0);"
                  title="Tuyển đơn hàng phụ nhà hàng và đầu bếp tại Rumani"
                  ><h3 data-v-8ff56982="">
                    Tuyển đơn hàng phụ nhà hàng và đầu bếp tại Rumani
                  </h3>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Mức lương:</span>1000 USD
                  </h4>
                  <h4 data-v-8ff56982="">
                    <span data-v-8ff56982="">Quốc gia:</span>Rumani
                  </h4></a
                >
              </li>
            </ul>
          </div>
          <div style="margin-bottom: 2rem">END</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addlist: [
        // {
        // 	0: '北京',
        // 	1: '上海',
        // 	2: '广州',
        // 	3: '深圳'
        // },
        // {
        // 	0: '南京',
        // 	1: '长沙',
        // 	2: '重庆',
        // 	3: '西安'
        // }
      ],
    };
  },
  methods: {
    addgo(data) {
      if (!localStorage.getItem("token")) {
        this.$router.push({ path: "/Login" });
      } else {
        this.$router.push({
          path: "/list?id=" + data.id + "&name=" + data.name,
        });
      }
    },
    getAddress() {
      this.$http({
        method: "get",
        url: "address_list",
      }).then((res) => {
        this.addlist = res.data;
      });
    },
  },
  created() {
    this.getAddress();
  },
};
</script>

<style lang="less" scoped>
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.nav-bar {
  background: linear-gradient(90deg, #7e5678, #e6c3a1);
  height: 100px;
}
.van-nav-bar {
  line-height: 50px;
}
::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
.van-sidebar {
  width: 180px;
}
.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #f2f2f5;
}

.linear-gradient {
  background: linear-gradient(270deg, #e6c3a1, #7e5678);
}

.text-center {
  text-align: center;
}

.mb-1 {
  margin-bottom: 1rem;
}

.border-15 {
  border-radius: 15px;
}

.color-white {
  color: #fff !important;
}

::v-deep .van-tab {
  font-size: 30px;
  line-height: 100px;
  font-weight: bold;
}

::v-deep .van-tabs__content--animated {
  overflow: unset;
}

::v-deep .van-tabs__line {
  background-color: #7e5678;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}
.card {
  background-color: #8a637d;
  padding: 0.625rem;
  width: 95%;
  color: white;
  margin: 0.625rem auto;
  border-radius: 0.375rem;
}
::v-deep .van-row--flex {
  height: 80px;
  line-height: 80px;
}
.rig-box {
  width: 95%;
  margin: 0.625rem auto;
}
.rig-title {
  color: #0bdab0;
  font-size: 1.125rem;
}
.rig-content {
  font-size: 20px;
  // margin-top: 10px;
}
.address {
  width: 90%;
  margin: 0 auto;
}
</style>
