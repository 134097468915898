<template>
  <van-tabbar
    v-if="show"
    v-model="active"
    active-color="#ac6015"
    :border="true"
    inactive-color="#979799"
  >
    <van-tabbar-item v-for="(k, key) in item" replace :to="k.router" :key="key">
      <span>{{ k.title }}</span>
      <template #icon="props">
        <img
          :src="props.active ? k.icon.active : k.icon.noactive"
          :alt="k.title"
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      active: 0,
      item: [
        {
          router: "/Home",
          title: "Trong nước",
          icon: {
            active: "/img/footer/sys.png",
            noactive: "/img/footer/sy.png",
          },
        },
        {
          router: "/Choose",
          title: "Nước ngoài",
          icon: {
            active: "/img/footer/beauty.52660ad1s.png",
            noactive: "/img/footer/beauty.52660ad1.png",
          },
        },
        {
          router: "/Game",
          title: "Hoàn thiện",
          icon: {
            active: "/img/footer/yys.png",
            noactive: "/img/footer/yy.png",
          },
        },
        {
          router: "/Video",
          title: "Công ty",
          icon: {
            active: "/img/footer/sps.png",
            noactive: "/img/footer/sp.png",
          },
        },
        {
          router: "/Mine",
          title: "Cá nhân",
          icon: {
            active: "/img/footer/ws.png",
            noactive: "/img/footer/w.png",
          },
        },
      ],
    };
  },
  methods: {},
  watch: {
    $route(to) {
      if (to.name == "home") {
        this.active = 0;
        this.show = true;
      } else if (to.name == "choose") {
        this.active = 1;
        this.show = true;
      } else if (to.name == "game") {
        this.active = 2;
        this.show = true;
      } else if (to.name == "video") {
        this.active = 3;
        this.show = true;
      } else if (to.name == "mine") {
        this.active = 4;
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  created() {
    if (this.$route.name == "home") {
      this.active = 0;
      this.show = true;
    } else if (this.$route.name == "choose") {
      this.active = 1;
      this.show = true;
    } else if (this.$route.name == "game") {
      this.active = 2;
      this.show = true;
    } else if (this.$route.name == "video") {
      this.active = 3;
      this.show = true;
    } else if (this.$route.name == "mine") {
      this.active = 4;
      this.show = true;
    } else {
      this.show = false;
    }
  },
};
</script>

<style lang="less" scoped>
@tabbar-height: 90px;
@tabbar-img: 55px;
.van-tabbar {
  height: @tabbar-height;
}
.van-tabbar-item__icon img {
  height: @tabbar-img;
}
.van-tabbar-item {
  font-size: 26px;
}
</style>
